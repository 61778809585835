export const Location = () => {
  return (
    <section className="py-24">
      <h2 className="text-center font-bold text-4xl mb-12">Our Location 🌍</h2>
      <div className="flex">
        <div
          className="hidden md:block p-9 text-4xl font-bold text-center bg-purple-950 text-white"
          style={{
            writingMode: "vertical-rl",
            textOrientation: "mixed",
            letterSpacing: 24,
          }}
        >
          LOCATION
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d997.2052332467827!2d36.825076142316085!3d-1.2811560946132072!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f110d128f71a3%3A0x976e4710749889f4!2sRaya%20hotel!5e0!3m2!1sen!2s!4v1728760519520!5m2!1sen!2s"
          title="location_of_the_hotel"
          className="w-full"
          height="450"
          loading="lazy"
          allowFullScreen
        />
      </div>
    </section>
  );
};
