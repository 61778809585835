import { IconType } from "react-icons";
import { IoCallOutline, IoLogoWhatsapp } from "react-icons/io5";
import { LuMail } from "react-icons/lu";
import { MdOutlinePhoneAndroid } from "react-icons/md";

export const Footer = () => {
  return (
    <div className="p-9 flex justify-around gap-24 flex-wrap">
      <AboutUs />
      <div className="flex justify-center gap-24 flex-wrap">
        <ContactUs />
        <Address />
      </div>
    </div>
  );
};

const AboutUs = () => {
  return (
    <div className="" style={{ width: 450 }}>
      <h2 className=" text-center font-bold text-4xl mb-12">
        About Our Hotel 😊
      </h2>
      <div className="text-center">
        {" "}
        <p>
          🌆 Nestled in central Nairobi, Raya Hotel blends luxury and comfort
          for both business and leisure travelers. ✨ With modern amenities,
          top-tier service, and a prime location near key attractions, your stay
          promises unforgettable moments. 💼 Whether for meetings or exploring
          the city's vibrant culture, enjoy fine dining, modern rooms, and
          personalized care. 🛏️🍽️
        </p>
      </div>
    </div>
  );
};

const ContactUs = () => {
  const contacts: [string, IconType][] = [
    ["+254 708 428 413", IoLogoWhatsapp],
    ["+254 113 376 423", MdOutlinePhoneAndroid],
    ["+254 106 639 909", IoCallOutline],
    ["rayahotelnairobi@gmail.com", LuMail],
  ];

  return (
    <div className="">
      <h2 className="text-center font-bold text-4xl mb-12">Contact Us 🤙</h2>
      <div className="flex flex-col gap-3  text-center">
        {contacts.map(([name, Logo]) => (
          <div className="flex items-center gap-6">
            <Logo className="text-2xl" />
            {name}
          </div>
        ))}
      </div>
    </div>
  );
};

const Address = () => {
  const contacts: [string, IconType][] = [
    ["39, River Road, Nairobi", IoLogoWhatsapp],
    ["Next to Eastmatt", MdOutlinePhoneAndroid],
    ["Opposite of Bank of Africa", IoCallOutline],
  ];

  return (
    <div className="">
      <h2 className="text-center font-bold text-4xl mb-12">Our Address 📍</h2>
      <div className="flex flex-col gap-3  text-center">
        {contacts.map(([name, Logo]) => (
          <div className="flex items-center gap-6">
            <Logo className="text-2xl" />
            {name}
          </div>
        ))}
      </div>
    </div>
  );
};
