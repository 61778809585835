import { IoCallOutline } from "react-icons/io5";
import logo from "../assets/main_poster.jpg";

export const Hero = () => {
  return (
    <div>
      <div
        className="flex items-center bg-no-repeat bg-bottom bg-cover "
        style={{ backgroundImage: `url(${logo})`, height: 600 }}
      >
        <div className="h-full w-3 lg:w-9 xl:w-16 2xl:w-24 hidden md:flex bg-white"></div>
        <div
          className="h-full p-9 pr-48 hidden md:flex flex-col gap-12 items-start justify-center "
          style={{
            width: 600,
            background:
              "linear-gradient(to right, white, rgba(255, 255, 255, .9), transparent)",
          }}
        >
          <h2 className="text-6xl">
            {" "}
            <b>Raya Hotel</b>{" "}
            <span className="text-4xl font-light">Nairobi</span>
          </h2>
          <p>
            Discover a blend of luxury and comfort at Raya Hotel Nairobi, where
            modern amenities meet warm hospitality. Conveniently located near
            the city's top attractions, we offer an unforgettable experience for
            both business and leisure travelers.
          </p>
          <a
            href="tel:+254708428413"
            className="py-3 px-9 flex justify-center items-center gap-3 text-xl bg-purple-700 text-white rounded-3xl hover:bg-purple-800 hover:shadow-purple-300 hover:shadow-xl"
          >
            <IoCallOutline />
            <span>Call to book</span>
          </a>
        </div>
      </div>
      <div
        className="p-24 w-full flex md:hidden flex-col gap-12 items-start rounded-3xl"
        style={{
          background:
            "linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, .9), rgba(255, 255, 255, .7), rgba(255, 255, 255, .0))",
        }}
      >
        <h2 className="text-6xl">
          {" "}
          <b>Raya Hotel</b> <span className="text-4xl font-light">Nairobi</span>
        </h2>
        <p>
          Discover a blend of luxury and comfort at Raya Hotel Nairobi, where
          modern amenities meet warm hospitality. Conveniently located near the
          city's top attractions, we offer an unforgettable experience for both
          business and leisure travelers.
        </p>
        <a
          href="tel:+254708428413"
          className="py-3 px-9 flex justify-center items-center gap-3 text-xl bg-purple-700 text-white rounded-3xl hover:bg-purple-800 hover:shadow-purple-300 hover:shadow-xl"
        >
          <IoCallOutline />
          <span>Call to book</span>
        </a>
      </div>
      {/* <div
            className="h-full "
            style={{
              background:
                "linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, .9), rgba(255, 255, 255, .5), transparent, transparent, transparent)", // White gradient
            }}
          >
            hel
          </div> */}
      {/* <div
          className="bg-center bg-no-repeat bg-cover"
          style={{ backgroundImage: `url(${logo})`, height: 600 }}
        >
          <div className="h-full flex justify-between">
            <div>the transparent part</div>
            <div
              className="w-2/3 flex justify-end items-center p-9"
              style={{
                background:
                  "linear-gradient(to left, black, rgba(0, 0, 0, .9), , rgba(0, 0, 0, .7)transparent)", // White gradient
              }}
            >
              <div className="w-1/2 text-white">
                <h2 className="text-6xl mb-12">
                  {" "}
                  <b>Raya Hotel</b>{" "}
                  <span className="text-4xl font-light">Nairobi</span>
                </h2>
                <p>
                  Discover a blend of luxury and comfort at Raya Hotel Nairobi,
                  where modern amenities meet warm hospitality. Conveniently
                  located near the city's top attractions, we offer an
                  unforgettable experience for both business and leisure
                  travelers.
                </p>
              </div>
            </div>
          </div>
        </div> */}
    </div>
  );
};
