import { IconType } from "react-icons";
import { HiWifi } from "react-icons/hi";
import { IoRestaurantOutline } from "react-icons/io5";
import { MdOutlineRoomService } from "react-icons/md";
import { TbClockCheck } from "react-icons/tb";
import { GiMagicBroom } from "react-icons/gi";
import { IoTvOutline } from "react-icons/io5";
import { LuShowerHead } from "react-icons/lu";

export const Amenities = () => {
  const amenitiesDetails: [string, IconType][] = [
    ["Free Wi-Fi", HiWifi],
    ["Restaurant", IoRestaurantOutline],
    ["Room Service", MdOutlineRoomService],
    ["24-Hour Reception", TbClockCheck],
    ["Daily Housekeeping", GiMagicBroom],
    ["TV", IoTvOutline],
    ["Hot Shower", LuShowerHead],
  ];

  return (
    <div className="px-12 pt-12 md:pt-36">
      <h2 className="flex justify-center font-bold text-4xl mb-12">
        Our Amenities
      </h2>
      <div className="flex justify-center flex-wrap gap-9 p-12">
        {amenitiesDetails.map((amenity) => Amenity(...amenity))}
      </div>
    </div>
  );
};

const Amenity = (name: string, Logo: IconType) => {
  return (
    <div className="p-6 w-60 shrink-0 flex flex-col justify-center items-center gap-12 rounded-lg text-purple-900 bg-purple-50 hover:bg-purple-900 hover:text-purple-50">
      <Logo className="text-7xl" />
      <span className="text-center text-xl font-bold">{name}</span>
    </div>
  );
};
