import { Amenities } from "./components/amenities";
import { AppBar } from "./components/app_bar";
import { Footer } from "./components/footer";
import { Hero } from "./components/hero";
import { Location } from "./components/location";

export const App = () => {
  return (
    <div className="flex flex-col mb-12">
      <AppBar />
      <Hero />
      <Amenities />
      <Location />
      <Footer />
    </div>
  );
};
