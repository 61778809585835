import logo from "../assets/logo.png";
import { IoCallOutline } from "react-icons/io5";

export const AppBar = () => {
  return (
    <div className="m-6 flex justify-between items-center ">
      <img src={logo} alt="Hotel logo" width={210} />
    </div>
  );
};
